import {
  get,
  set,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  invoiceSchema,
  invoicesSchema,
  notesSchema,
  objectsSchema,
  upsertNormalizedEntities,
  workOrdersSchema,
} from '../../../helpers/normalizers';
import {
  convertInvoiceFromLoad,
} from '../../../views/Admin/Invoice/Form/converters';
import {
  actions as invoiceRequestActions,
} from '../../request/invoice/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import {
  actions as workOrderRequestActions,
} from '../../request/workOrder/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as invoiceViewActions } from '../../view/invoice/actions';
import { invoiceViewSelector } from '../../view/invoice/selectors';
import {
  fetchFileFromAPI,
  fetchFileStreamFromAPI,
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  fetchObjectsFromAPIV2,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { invoiceSelector } from './selectors';
import { toast } from 'react-toastify';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 * @param tableState
 * @returns {Function}
 */
export const fetchObjects = (tableState, additionalFilters) => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadAllError(null));
    dispatch(invoiceRequestActions.setIsLoadingAll(true));
    try {
      let url = constants.FETCH_LIST_URL;
      const query = new URLSearchParams(additionalFilters).toString();
      if(query){
        url = `${url}?${query}`;
      }

      const invoices = await fetchObjectsFromAPI(url);
      // If tableState.page is greater than our total results, switch to page 1.
      const currentPage = get(tableState, 'page');
      const limit = get(tableState, 'limit');
      if(invoices.length < ((currentPage - 1) * limit)){
        set(tableState, 'page', 1);
      }
      // Normalize the result and store the invoices in redux
      const entities = get(normalize(invoices, invoicesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(invoices, tableState, (ids, count) => {
          dispatch(invoiceViewActions.setList(ids, count));
        })
      );
      dispatch(invoiceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Load a list of objects (PORTAL)
 * @param tableState
 * @returns {Function}
 */
export const fetchPortalObjects = tableState => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadAllError(null));
    dispatch(invoiceRequestActions.setIsLoadingAll(true));
    try {
      const invoices = await fetchObjectsFromAPIV2(constants.FETCH_PORTAL_LIST_URL, tableState);

      // Normalize the result and store the invoices in redux
      const entities = get(normalize(get(invoices, "data"), invoicesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      dispatch(
        invoiceViewActions.setList(
          get(invoices, "data").map(invoice => {
            return invoice.id;
          }),
          get(invoices, "meta.total")
        )
      );
      dispatch(invoiceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setSaveError(null));
    dispatch(invoiceRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);

      if (result.invoice && result.invoice.id) {
        await dispatch(fetchObject(result.invoice.id));
      }
      if (onSuccess) {
        onSuccess(result.invoice);
      }

      // Set isSaving to false so form state can be updated.
      dispatch(invoiceRequestActions.setIsSaving(false));
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setSaveError(getErrorMessage(e)));
      // Toast the errors to the form.
      const errorMessages = get(e, 'response.data.errors.grid', []);
      if(errorMessages.length > 0){
        errorMessages.forEach(message => {
          toast.error(message, {
            autoClose: 5000,
          });
        });
        dispatch(invoiceRequestActions.setSaveError(errorMessages.join("\n")));
      }
      dispatch(invoiceRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param invoiceId
 * @returns {Function}
 */
export const fetchInvoiceMailPdf = invoiceId => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadError(null));
    dispatch(invoiceRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_INVOICE_MAIL_URL.replace(":invoiceId", invoiceId),
        `invoice-${invoiceId}-mail.pdf`
      );
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param invoiceId
 * @returns {Function}
 */
export const fetchInvoiceReceiptPdf = (invoiceId, download = true) => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadError(null));
    dispatch(invoiceRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_INVOICE_RECEIPT_URL.replace(":invoiceId", invoiceId),
        `invoice-${invoiceId}-receipt.pdf`,
        download,
        'application/pdf'
      );
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param invoiceId
 * @returns {Function}
 */
export const fetchInvoiceReceiptForPortal = invoiceId => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadError(null));
    try {
      const blob = await fetchFileStreamFromAPI(
        constants.FETCH_PORTAL_INVOICE_RECEIPT_URL.replace(":invoiceId", invoiceId),
        `invoice-${invoiceId}-receipt.pdf`,
        'application/pdf'
      );
      return blob;

    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadError(getErrorMessage(e)));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param invoiceId
 * @returns {Function}
 */
export const fetchInvoiceGiftPdf = invoiceId => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadError(null));
    dispatch(invoiceRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_INVOICE_GIFT_URL.replace(":invoiceId", invoiceId),
        `invoice-${invoiceId}-receipt.pdf`
      );
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        invoiceViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "invoice";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = invoiceViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        invoiceViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const invoices = invoiceSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(invoices, tableState, (ids, count) => {
        dispatch(invoiceViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadError(null));
    dispatch(invoiceRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data.invoice"
      );

      if (get(result, "taxjar_id")) {
        result.taxes = await fetchObjectsFromAPI(
          `${constants.FETCH_TAXES_URL}/${get(result, "taxjar_id")}`,
          "data.taxes"
        );
      } else if (get(result, "lines", []).length && get(result, "lines")[0].taxjar_id ) {
        result.taxes = await fetchObjectsFromAPI(
          `${constants.FETCH_TAXES_URL}/${get(result, "lines")[0].taxjar_id}`,
          "data.taxes"
        );
      }

      const invoice = convertInvoiceFromLoad(result);

      // Normalize the result and store the other attributes in redux
      const entities = get(normalize(invoice, invoiceSchema), "entities", {});

      dispatch(upsertNormalizedEntities(entities));
      dispatch(invoiceRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param paymentId
 * @param invoiceId
 * @returns {Function}
 */
export const reversePayment = (paymentId, invoiceId) => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setSaveError(null));
    dispatch(invoiceRequestActions.setIsSaving(true));
    try {
      await fetchObjectsFromAPI(
        `${constants.REVERSE_PAYMENT_URL}/${paymentId}`,
        null
      );
      if (invoiceId) {
        dispatch(fetchObject(invoiceId));
      }
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the work orders associated with this invoice
 * @param invoiceId
 * @returns {Function}
 */
export const fetchWorkOrders = invoiceId => {
  return async dispatch => {
    dispatch(workOrderRequestActions.setLoadAllError(null));
    dispatch(workOrderRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_WORK_ORDER_LIST_URL.replace(":invoiceId", invoiceId),
        "data"
      );

      const entities = get(
        normalize(result, { ...objectsSchema, workorders: workOrdersSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        invoiceViewActions.setRelatedObjects(
          constants.RELATION_WORK_ORDERS,
          invoiceId,
          result.workorders.map(workOrder => {
            return workOrder.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Reset.
 * @param id
 * @returns {Function}
 */
export const reset = () => {
  return async (dispatch, getState) => {
    dispatch(invoiceRequestActions.setIsLoading(false));
    dispatch(invoiceRequestActions.setIsLoadingAll(false));
    dispatch(invoiceRequestActions.setIsLoadedAll(false));
    dispatch(upsertNormalizedEntities([]));
    dispatch(invoiceViewActions.setList([], 0));
  };
};

export const actions = {
  ...objectActions,
  updateSortFilterLimit,
  fetchNotes,
  fetchObject,
  fetchInvoiceMailPdf,
  fetchInvoiceReceiptPdf,
  fetchInvoiceReceiptForPortal,
  fetchInvoiceGiftPdf,
  fetchObjects,
  fetchPortalObjects,
  fetchWorkOrders,
  reversePayment,
  upsertNote,
  upsertObject,
  reset,
};
