import cx from "classnames";
import React from "react";
import { Select } from "semantic-ui-react";

export const MonthSelect = ({
  id,
  label,
  placeholder,
  required,
  onSelect,
  value
}) => {
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true
  });

  const options = [
    {
      value: 1,
      key: 1,
      text: "January"
    },
    {
      value: 2,
      key: 2,
      text: "February"
    },
    {
      value: 3,
      key: 3,
      text: "March"
    },
    {
      value: 4,
      key: 4,
      text: "April"
    },
    {
      value: 5,
      key: 5,
      text: "May"
    },
    {
      value: 6,
      key: 6,
      text: "June"
    },
    {
      value: 7,
      key: 7,
      text: "July"
    },
    {
      value: 8,
      key: 8,
      text: "August"
    },
    {
      value: 9,
      key: 9,
      text: "September"
    },
    {
      value: 10,
      key: 10,
      text: "October"
    },
    {
      value: 11,
      key: 11,
      text: "November"
    },
    {
      value: 12,
      key: 12,
      text: "December"
    }
  ];

  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        id={id}
        search
        value={value}
        selection
        placeholder={placeholder}
        fluid
        options={options}
        onChange={(e, { value }) => {
          onSelect(value);
        }}
      />
    </div>
  );
};
