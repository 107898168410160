import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as serviceLevelObjectActions,
} from '../../../store/object/serviceLevel/actions';
import {
  serviceLevelSelector,
} from '../../../store/object/serviceLevel/selectors';
import {
  serviceLevelRequestSelector,
} from '../../../store/request/serviceLevel/selectors';

const ServiceLevelSelectComponent = ({
  disabled,
  fetchServiceLevels,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  placeholder,
  onSelectServiceLevel,
  serviceLevelId,
  serviceLevels,
  required,
  upward,
}) => {
  if (input && input.value) {
    serviceLevelId = input.value;
  }

  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchServiceLevels();
    }
  }, [isLoadedAll, isLoadingAll, fetchServiceLevels, loadAllError]);

  const serviceLevelOptions = createOptionsFromObjects(serviceLevels);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-2": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!serviceLevelId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        {...input}
        upward={upward}
        error={required && !serviceLevelId}
        loading={isLoadingAll}
        fluid
        disabled={disabled}
        size="huge"
        value={serviceLevelId}
        search
        name="serviceLevel"
        placeholder={placeholder}
        selection
        clearable
        options={serviceLevelOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectServiceLevel) {
            onSelectServiceLevel(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

ServiceLevelSelectComponent.propTypes = {
  fetchServiceLevels: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  onSelectServiceLevel: func,
  placeholder: string,
  serviceLevels: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const serviceLevelObjectSelector = serviceLevelSelector();
  const requestSelector = serviceLevelRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    serviceLevels: serviceLevelObjectSelector.getDenormalizedObjects()(state)
  };
};

export const ServiceLevelSelect = connect(
  mapStateToProps,
  {
    fetchServiceLevels: serviceLevelObjectActions.fetchObjects
  }
)(ServiceLevelSelectComponent);
