import {
  composeValidators,
  email,
  pin,
  required,
  renderPinField,
  renderSelect,
} from "../../../../../helpers/form";

export const passwordFields = [
  {
    name: "password",
    label: "Password",
    size: "large",
    inputType: "password",
    required: true,
    validate: required,
  },
  {
    name: "password2",
    label: "Password Confirm",
    size: "large",
    inputType: "password",
    required: true,
    validate: required,
  },
];

export const infoFields = (locations) => {
  return [
    {
      name: "name",
      label: "Name",
      size: "large",
      required: true,
      validate: required,
    },
    {
      name: "email",
      label: "Email",
      size: "large",
      required: true,
      validate: composeValidators(required, email),
    },
    {
      name: "phone",
      label: "Phone",
      size: "large",
    },
    {
      name: "default_location.id",
      label: "Default Location",
      renderer: renderSelect,
      options: locations.filter((location) => location.active === 'Y').map((location) => {
        return { key: location.id, value: location.id, text: location.name };
      }),
    },
    {
      name: "pin",
      label: "4-Digit Pin",
      size: "large",
      inputType: "number",
      inputMode: "numeric",
      maxLength: 4,
      renderer: renderPinField,
      validate: composeValidators(pin),
    },
  ];
};

export const customerUserInfoFields = () => {
  return [
    {
      name: "name",
      label: "Name",
      size: "large",
      disabled: true,
      validate: required,
    },
    {
      name: "email",
      label: "Email",
      size: "large",
      disabled: true,
      validate: composeValidators(required, email),
    },
    {
      name: "phone",
      label: "Phone",
      size: "large",
      disabled: true,
    },
  ];
};

export const routeLeaderFields = [
  {
    name: "display_name",
    label: "Name",
    size: "large",
  },
  {
    name: "display_email",
    label: "Email",
    size: "large",
    validate: email,
  },
  {
    name: "display_phone",
    label: "Phone",
    size: "large",
  },
];
