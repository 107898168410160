import React, { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { bool, func } from "prop-types";
import { toast } from "react-toastify";

export const PhotoUploadModal = ({ open, onClose, onUpload, loading }) => {
  const [file, setFile] = useState();
  const [previewSrc, setPreviewSrc] = useState();
  const fileInputRef = React.createRef();
  const handleFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = function(event) {
      setPreviewSrc(event.target.result);
    };
    const validFilesize =
      (e.currentTarget.files[0].size / 1024 / 1024).toFixed(4) <= 2;
    if (e.currentTarget.files[0] && validFilesize) {
      reader.readAsDataURL(e.currentTarget.files[0]);
      // CHECK SIZE
      setFile(e.currentTarget.files[0]);
    } else {
      if (!validFilesize) {
        toast.error("Please select a file less than 2MB");
      } else {
        toast.error("Please select a valid photo.");
      }
    }
  };
  return (
    <Modal open={open} onClose={onClose} size="mini">
      <Modal.Header>Upload a photo</Modal.Header>
      <Modal.Content>
        <div className="upload-preview-container d-flex p-1 align-items-center">
          {previewSrc ? (
            <div className="m-auto">
              <img alt="User" src={previewSrc} />
            </div>
          ) : (
            <div className="d-flex align-items-center justify-flex-center h-100 w-100 fs-7 text-gray">
              <Icon name="image" />
            </div>
          )}
        </div>
        <div className="text-center pb-2 fs-2">
          <em>Please select a square photo (1:1 aspect ratio)[MAX:2MB]</em>
        </div>
        <Button
          fluid
          onClick={() => {
            if (fileInputRef) {
              fileInputRef.current.click();
            }
          }}
        >
          Select photo
        </Button>
        <input
          type="file"
          className="d-none"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!file}
          loading={loading}
          positive
          onClick={() => {
            onUpload(file);
          }}
        >
          Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

PhotoUploadModal.defaultProps = {
  open: false,
};

PhotoUploadModal.propTypes = {
  open: bool,
  onClose: func.isRequired,
  onUpload: func.isRequired,
};
