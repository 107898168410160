import cx from "classnames";
import React from "react";
import dayjs from "dayjs";
import { Select } from "semantic-ui-react";

export const YearSelect = ({
  id,
  label,
  placeholder,
  required,
  onSelect,
  value
}) => {
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true
  });

  const options = [];

  let year = 2017;
  const thisYear = dayjs().format("YYYY");
  while (year <= thisYear) {
    year++;
    options.push({ key: year, value: year, text: year });
  }

  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        id={id}
        search
        value={value}
        selection
        placeholder={placeholder}
        fluid
        options={options}
        onChange={(e, { value }) => {
          onSelect(value);
        }}
      />
    </div>
  );
};
