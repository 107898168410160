import React, { useState } from "react";

import cx from "classnames";
import { func, string, bool, array, object, number } from "prop-types";
import { Select } from "semantic-ui-react";

import { BUCKET_LIST, BUCKET_LIST_FULL } from "../../../constants/general";
import { createOptionsFromObjects } from "../../../helpers/form/util";

const BucketSelectComponent = ({
  disabled,
  id,
  input,
  label,
  bucketId,
  buckets,
  onSelectBucket,
  onSelectBucketId,
  placeholder,
  required,
}) => {
  if (input && input.value) {
    bucketId = input.value;
  }
  const [hasFocus, setHasFocus] = useState(false);
  const bucketOptions = createOptionsFromObjects(
    BUCKET_LIST.map((value) => {
      return {
        id: value,
        name: BUCKET_LIST_FULL[value],
      };
    })
  );
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!bucketId,
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={bucketId}
        search
        name="bucket"
        placeholder={placeholder}
        selection
        options={bucketOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectBucket) {
            onSelectBucket(buckets.find((bucket) => bucket.id === value));
          }
          if (onSelectBucketId) {
            onSelectBucketId(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

BucketSelectComponent.propTypes = {
  bucketId: string,
  onSelectBucket: func,
  onSelectBucketId: func,
  placeholder: string,
  disabled: bool,
  id: number,
  input: object,
  label: string,
  buckets: array,
  required: bool,
};

export const BucketSelect = BucketSelectComponent;
