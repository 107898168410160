import { isEmpty, has, get } from "lodash";
import dayjs from "dayjs";
import { round } from "./util";
import * as accounting from "accounting";
import { INVOICE_TYPE_DELIVERY, INVOICE_TYPE_SHIPMENT, INVOICE_TYPE_PICKUP, INVOICE_TYPE_BED } from './../constants/general';
import { toast } from "react-toastify";
/**
 * If we try to add a product to the invoice that already exists, we just update its quantity
 * @param item
 * @param lines
 * @returns {[]|*[]}
 */
export const mergeInvoiceLines = (item, lines) => {
  if (isEmpty(lines)) {
    return [{
      ...item,
      ...calculateLineItemAmount(
        item.price,
        item.qty,
        item.discount_pct
      ),
    }];
  }
  const mergedLines = [];
  let found = false;
  lines.forEach((line) => {
    // If item is not a custom misc item, quantity is being updated.
    if (has(line, "sku") && line.sku === get(item, "sku")) {
      const isDisabled =
        get(line, "related_action_lines", []).filter((line) => {
          return line.status !== "reserved" && line.status !== "not reserved";
        }).length > 0;
      found = true;
      if (isDisabled) {
        toast.error("Cannot update quantity of item that has been reserved.");
        mergedLines.push(line);
      } else {
        // Work order line not in progress, okay to adjust quantity.
        mergedLines.push({ 
          ...line, 
          qty: parseInt(line.qty) + parseInt(item.qty),
          ...calculateLineItemAmount(
            line.price,
            parseInt(line.qty) + parseInt(item.qty),
            line.discount_pct
          ),
        });
      }
    } else {
      // Only merge existing non-custom items.
      mergedLines.push({
        ...line,
        ...calculateLineItemAmount(
          line.price,
          line.qty,
          line.discount_pct
        ),
      });
    }
  });

  if (!found) {
    mergedLines.push({
      ...item,
      ...calculateLineItemAmount(
        item.price,
        item.qty,
        item.discount_pct
      ),
    });
  }

  return mergedLines;
};

/**
 * Utility function for merging changes into the line items
 * @param items
 * @param itemToUpdate
 * @param update
 * @returns {[]}
 */
export const updateInvoiceItems = (items, itemToUpdate, update) => {
  const updatedItems = [];
  items.forEach((i) => {
    if (i.id !== itemToUpdate.id) {
      updatedItems.push(i);
    } else {
      updatedItems.push({ ...itemToUpdate, ...update });
    }
  });
  return updatedItems;
};

/**
 * Utility function for merging changes into the line items
 * @param items
 * @param itemToRemove
 * @returns {[]}
 */
export const removeInvoiceItem = (items, itemToRemove, id = "id") => {
  const updatedItems = [];
  items.forEach((i) => {
    if (i[id] !== itemToRemove[id]) {
      updatedItems.push(i);
    }
  });
  return updatedItems;
};

/**
 * Formats the price entered on the item table so that it has two decimals
 * @param items
 * @returns {*[]}
 */
export const formatInvoiceItemsPrice = (items) => {
  if (!isEmpty(items)) {
    const updatedItems = [...items];
    updatedItems.forEach((item) => {
      item.price = parseFloat(item.price);
    });
    return updatedItems;
  }
  return items;
};

export const calculateLineItemAmount = (price, qty, discountPercent) => {
  const discount = accounting.toFixed((discountPercent / 100) * (price * qty), 2);
  const ext = (price * qty) - discount;
  return { ext, extended_price: ext, amount: ext, discount };
};

/**
 * Assert if current state of invoice has
 * change due to customer.
 * @param invoice 
 */
export const isChangeDue = (invoice) => {
  return (
    get(invoice, "payments") &&
    get(invoice, "payments", []).reduce((accumulator, item) => {
      return item.changeDue ? accumulator + parseFloat(item.changeDue) : 0;
    }, 0) > 0
  );
};

/**
 * Get change due from current state of invoice
 * @param invoice 
 */
export const changeDue = (invoice) => {
  return round(
    get(invoice, "payments") &&
      get(invoice, "payments", []).reduce((accumulator, item) => {
        return item.changeDue ? accumulator + parseFloat(item.changeDue) : 0;
      }, 0),
    2
  ).toFixed(2) || 0;
};

export const getLocationCodeById = (allLocations, id) => {
  const location = allLocations.filter((location) => {
    return location.id === id;
  }).shift();
  return get(location, "code", null);
};

export const maximumReturnableItems = (lines, sku) => {
  let totalOnInvoice = lines.reduce((acc, item) => {
    return getSku(item) === sku ? acc + parseFloat(item.qty) : acc;
  }, 0);
  return totalOnInvoice;
};

export const getSubtotal = lines => {
  return lines.reduce((accumulator, line) => {
    if (get(line, "extended_price")) {
      return accumulator + parseFloat(line.extended_price);
    } else if (get(line, "ext")) {
      return accumulator + parseFloat(line.ext);
    } else {
      return accumulator;
    }
  }, 0) || 0;
};

export const getTaxableSubtotal = lines => {
  return lines.reduce((accumulator, line) => {
    if(!line.taxable || line.taxable === "n"){
      return accumulator;
    }
    if (get(line, "extended_price")) {
      return accumulator + parseFloat(line.extended_price);
    } else if (get(line, "ext")) {
      return accumulator + parseFloat(line.ext);
    } else {
      return accumulator;
    }
  }, 0) || 0;
};

export const getSku = item => {
  if(get(item, 'sku')){
    return get(item, 'sku');
  }
  if(get(item, 'good.sku')){
    return get(item, 'good.sku');
  }
  if(get(item, 'original_good_unserialized.sku')){
    return get(item, 'original_good_unserialized.sku');
  }
  return null;
}

export const validateInvoiceForm = (invoice) => {
  if (!get(invoice, "type")) {
    return false;
  }
  // If not a POS and we don't have a customer, we can't continue.
  if(get(invoice, 'type') !== 'POS' && !get(invoice, "customer")){
    return false;
  }
  // If invoice subtotal is 0, we can't continue, unless we also have line items
  if(get(invoice, 'subtotal') === 0 && get(invoice, 'lines', []).length === 0){
    if (get(invoice, "payments", []).length === 0 && get(invoice, "id")) {
      // If we have no payments, we can continue.
      return validateInvoiceShipping(invoice);
    }
    return false;
  }
  return validateInvoiceShipping(invoice);
};

export const validateInvoiceShipping = invoice => {
  let isValid;
  switch (get(invoice, "type")) {
    case INVOICE_TYPE_DELIVERY:
    case INVOICE_TYPE_BED:
      isValid = 
        !!get(invoice, "delivery_date") &&
        !!get(invoice, "delivery_info.expected_date") &&
        !!get(invoice, "delivery_name") &&
        !!get(invoice, "delivery_address1") &&
        !!get(invoice, "delivery_city") &&
        !!get(invoice, "delivery_state") &&
        !!get(invoice, "delivery_postal_code");        
      break;
    case INVOICE_TYPE_SHIPMENT:
      isValid =
        !!get(invoice, "shipment_date") &&
        !!get(invoice, "shipment_name") &&
        !!get(invoice, "shipment_address1") &&
        !!get(invoice, "shipment_city") &&
        !!get(invoice, "shipment_state") &&
        !!get(invoice, "shipment_postal_code");
      break;
    case INVOICE_TYPE_PICKUP:
      isValid = !!get(invoice, "pickup_date");
      break;
    default:
      isValid = true;
      break;
  }
  return isValid;
};

export const setDefaultDeliveryData = (deliveryData) => {
  const customer = get(deliveryData, "customer");
  const customerAddresses = get(deliveryData, "customer.addresses", []);
  const estimate = get(deliveryData, "estimate");
  if (!deliveryData.delivery_name && customer) {
    deliveryData.delivery_name = `${get(customer, 'first_name')} ${get(customer, 'last_name')}`;
  }
  // Fill form with data from estimate, if estimate exists.
  if (!deliveryData.delivery_date && estimate) {
    deliveryData.delivery_date = get(estimate, 'target_install_date');
  }
  if (!deliveryData.delivery_info && deliveryData.estimate) {
    deliveryData.delivery_info = { "expected_date": deliveryData.estimate.target_install_date };
  }
  if (!deliveryData.delivery_date && deliveryData.estimate) {
    deliveryData.delivery_date = deliveryData.estimate.target_install_date;
  }

  if (customer && customerAddresses.length > 0) {
    let address = customerAddresses[0];
    if (!deliveryData.delivery_address1) {
      deliveryData.delivery_address1 = get(address, 'address1');
    }
    if (!deliveryData.delivery_address2) {
      deliveryData.delivery_address2 = get(address, 'address2');
    }
    if (!deliveryData.delivery_city) {
      deliveryData.delivery_city = get(address, 'city');
    }
    if (!deliveryData.delivery_state) {
      deliveryData.delivery_state = get(address, 'state');
    }
    if (!deliveryData.delivery_postal_code) {
      deliveryData.delivery_postal_code = get(address, 'postal_code');
    }
  }
  if(!deliveryData.delivery_info){
    deliveryData.delivery_info = {};
  }
  if(!deliveryData.delivery_info.charge){
    // Set delivery_info.charge to delivery
    deliveryData.delivery_info.charge = parseFloat(deliveryData.delivery) || 0;
  }
  return {...deliveryData};
}

export const shippingDeliveryTitle = invoice => {
  switch (invoice.type) {
    case INVOICE_TYPE_SHIPMENT:
      if (get(invoice, "shipping_info.shipped_date")) {
        return `Shipped date: ${ dayjs(get(invoice, "shipping_info.shipped_date")).format("MM/DD/YYYY")}`;
      }
      if(get(invoice, "shipping_info.expected_date")){
        return `Requested shipping date: ${dayjs(get(invoice, "shipping_info.expected_date")).format("MM/DD/YYYY")}`;
      }
      if(get(invoice, "shipment_date")){
        return `Requested shipping date: ${dayjs(get(invoice, "shipment_date")).format("MM/DD/YYYY")}`;
      }
      return "Shipping & Delivery";
    case INVOICE_TYPE_DELIVERY:
    case INVOICE_TYPE_BED:
      if (get(invoice, "delivery_info.shipped_date")) {
        return `Delivered date: ${dayjs(get(invoice, "delivery_info.shipped_date")).format("MM/DD/YYYY")}`;
      }
      if(get(invoice, "delivery_info.expected_date")) {
        return `Requested delivery date: ${dayjs(get(invoice, "delivery_info.expected_date")).format("MM/DD/YYYY")}`;
      }
      if(get(invoice, "delivery_date")) {
        return `Requested delivery date: ${dayjs(get(invoice, "delivery_date")).format("MM/DD/YYYY")}`;
      }
      return "Shipping & Delivery";
    case INVOICE_TYPE_PICKUP:
      return `Pickup date: ${dayjs(get(invoice, "pickup_date")).format("MM/DD/YYYY")}`;
    default:
      return "Shipping & Delivery";
  }
}

