import { array, bool, func, object } from "prop-types";
import { get } from "lodash";
import React, { useState } from "react";
import { Form as FinalForm } from "react-final-form";
import {
  Button,
  Card,
  Form,
  Grid,
  Icon,
  Image,
  Modal,
  Segment,
} from "semantic-ui-react";
import { renderBasicForm } from "../../../../../helpers/form";
import { infoFields, customerUserInfoFields, passwordFields, routeLeaderFields } from "./fields";
import { FormSection } from "../../../../../components/Form";
import { DeleteConfirmCard } from "../../../../../components/DeleteConfirmCard";
import { AccessControlModal } from "../AccessControlModal";
import { PhotoUploadModal } from "./../../../../../components/PhotoUploadModal/index";
import { imageUri } from "./../../../../../helpers/util";
import { AssignedRoutesForm } from "../AssignedRoutesForm";
import { toast } from "react-toastify";
import { ROLE_ROUTE_LEADER } from "../../../../../constants";
import dayjs from "dayjs";
import arrayMutators from 'final-form-arrays';

export const UserDetailsForm = ({
  locations,
  routes,
  user,
  roles,
  groups,
  permissions,
  onSubmit,
  onDelete,
  isSaving,
  isLoading,
  isLoadingGroups,
  isLoadingRoles,
  isLoadingPermissions,
  uploadProfilePhoto,
  isStaff,
  authUser,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [photoUploadModalIsShowing, setPhotoUploadModalIsShowing] = useState();
  const [accessControlModalIsOpen, setAccessControlModalIsOpen] = useState(
    false
  );
  const [isShowPasswordFields, toggleShowPasswordFields] = useState(
    user && !user.id
  );
  const accounts = get(user, 'all_accounts', [])
  const accountPhoto = accounts.length > 0 ? accounts[0].customer.photo : null;
  const userPhoto = isStaff ? imageUri(get(user, "photo")) : imageUri(accountPhoto);
  if (!user) {
    return null;
  }
  return (
    <section className="user-details-form mb-2">
      <FinalForm
        mutators={{ ...arrayMutators }}
        initialValues={user}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          const { password, password2 } = values;
          if (password && password2 && password !== password2) {
            errors.password2 = "Passwords Must Match";
          }
          return errors;
        }}
      >
        {({ handleSubmit, valid, form }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Grid>
              <Grid.Row columns={3}>
                {get(user, 'id') && (
                  <Grid.Column width={3}>
                    <Card>
                      {userPhoto ? (
                        <Image src={userPhoto} wrapped ui={false} />
                      ) : (
                        <div>
                          <div className="default-avatar square"></div>
                        </div>
                      )}
                      <Card.Content>
                        <Card.Header>{get(user, "name")}</Card.Header>
                        <Card.Meta>
                          <span className="date">
                            Joined in{" "}
                            {dayjs(get(user, "created_at")).format("YYYY")}
                          </span>
                        </Card.Meta>
                        <Card.Description>
                          <Button
                            fluid
                            size="tiny"
                            color="green"
                            type="button"
                            className="mb-1"
                            onClick={() => {
                              setPhotoUploadModalIsShowing(true);
                            }}
                          >
                            <Icon name="upload" />
                            Upload Photo
                          </Button>
                          {isStaff && <Button
                            fluid
                            size="tiny"
                            className="mb-1"
                            loading={
                              isLoadingGroups ||
                              isLoadingRoles ||
                              isLoadingPermissions
                            }
                            disabled={
                              isLoadingGroups ||
                              isLoadingRoles ||
                              isLoadingPermissions
                            }
                            type="button"
                            primary
                            onClick={() => {
                              setAccessControlModalIsOpen(true);
                            }}
                          >
                            <Icon name="key" />
                            Access Control
                          </Button>}
                          {isStaff && <Button
                            fluid
                            type="button"
                            className="mb-1"
                            color="red"
                            size="tiny"
                            disabled={!valid}
                            onClick={() => {
                              setModalIsOpen(true);
                            }}
                          >
                            <Icon name="delete" />
                            Delete User
                          </Button>}
                        </Card.Description>
                      </Card.Content>
                      {isStaff && <Card.Content extra>
                        <div
                          className="pull-left pointer"
                          onClick={() => {
                            setAccessControlModalIsOpen(true);
                          }}
                        >
                          <Icon name="user" />
                          {get(user, "roles", []).length} Roles
                        </div>
                        <div
                          className="pull-right pointer"
                          onClick={() => {
                            setAccessControlModalIsOpen(true);
                          }}
                        >
                          <Icon name="key" />
                          {get(user, "permissions", []).length} Permissions
                        </div>
                      </Card.Content>}
                    </Card>
                  </Grid.Column>
                )}
                <Grid.Column width={6}>
                  <Segment>
                    <FormSection color="green" title="Info" className="h-100">
                      <>
                        {isStaff ? renderBasicForm(infoFields(locations)) : renderBasicForm(customerUserInfoFields())}
                        {isShowPasswordFields ? (
                          <>
                            {renderBasicForm(passwordFields)}

                            {user.id && (
                              <div className="mt-2">
                                <Button
                                  type="button"
                                  color="red"
                                  size="small"
                                  onClick={() => {
                                    toggleShowPasswordFields(false);
                                  }}
                                >
                                  Cancel Change Password
                                </Button>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="mt-2">
                            {isStaff && <Button
                              primary
                              type="button"
                              size="small"
                              onClick={() => {
                                toggleShowPasswordFields(true);
                              }}
                            >
                              Change Password
                            </Button>}
                          </div>
                        )}
                      </>
                    </FormSection>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={6}>
                  {isStaff && <Segment>
                    <FormSection
                      color="green"
                      className="h-100"
                      title="Route Leader Information"
                      fields={routeLeaderFields}
                      intro="Displayed at the bottom of statements"
                    />
                    <AssignedRoutesForm
                      title="Assigned Routes"
                      routes={routes}
                      form={form}
                      disabled={authUser.hasRole(ROLE_ROUTE_LEADER) === false}
                    />
                  </Segment>}
                  {isStaff && <footer className="mt-3 text-right">
                    <Button primary size="large" disabled={!valid}>
                      Submit
                    </Button>
                  </footer>}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <AccessControlModal
              form={form}
              isOpen={accessControlModalIsOpen}
              onClose={() => {
                setAccessControlModalIsOpen(false);
              }}
              roles={roles}
              groups={groups}
              permissions={permissions}
              user={user}
            />
            <PhotoUploadModal
              open={photoUploadModalIsShowing}
              loading={isLoading || isSaving}
              onClose={() => {
                setPhotoUploadModalIsShowing(false);
              }}
              onUpload={(file) => {
                uploadProfilePhoto(
                  get(user, "id"),
                  file,
                  (successResponse) => {
                    // Close modal and update customer.photo
                    toast.success("Success!", {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setPhotoUploadModalIsShowing(false);
                  },
                  (errorResponse) => {
                    // Display error message.
                    toast.warn("Uh oh, there was an error.", {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setPhotoUploadModalIsShowing(false);
                  }
                );
              }}
            />
          </Form>
        )}
      </FinalForm>
      <Modal
        open={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
      >
        <DeleteConfirmCard
          description={get(user, "name", "user")}
          onCancel={() => {
            setModalIsOpen(false);
          }}
          loading={isLoadingGroups || isLoadingRoles || isLoadingPermissions}
          guard={"admin.users.delete"}
          onDelete={onDelete}
        />
      </Modal>
    </section>
  );
};

UserDetailsForm.propTypes = {
  locations: array,
  routes: array,
  groups: array,
  onSubmit: func.isRequired,
  onDelete: func.isRequired,
  roles: array,
  permissions: array,
  user: object,
  authUser: object,
  isLoadingGroups: bool,
  isLoadingRoles: bool,
  isLoadingPermissions: bool,
};
