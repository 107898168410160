import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as productTypeObjectActions,
} from '../../../store/object/productType/actions';
import {
  productTypeSelector,
} from '../../../store/object/productType/selectors';
import {
  productTypeRequestSelector,
} from '../../../store/request/productType/selectors';

const ProductTypeSelectComponent = ({
  disabled,
  fetchProductTypes,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  placeholder,
  onSelectProductType,
  productTypeId,
  productTypes,
  required,
  name = 'productType',
}) => {
  if (input && input.value) {
    productTypeId = input.value;
  }

  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchProductTypes();
    }
  }, [isLoadedAll, isLoadingAll, fetchProductTypes, loadAllError]);

  const productTypeOptions = createOptionsFromObjects(productTypes);
  if (productTypeOptions.length > 0) {
    productTypeOptions.unshift({
      key: 'blank',
      value: '',
      text: 'Select Product Type',
    });
  }
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!productTypeId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        {...input}
        upward={false}
        clearable
        selectOnBlur={false}
        fluid
        disabled={disabled}
        size="huge"
        value={productTypeId}
        search
        name={name}
        placeholder={placeholder}
        selection
        options={productTypeOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectProductType) {
            onSelectProductType(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

ProductTypeSelectComponent.propTypes = {
  fetchProductTypes: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  onSelectProductType: func,
  placeholder: string,
  productTypes: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const productTypeObjectSelector = productTypeSelector();
  const requestSelector = productTypeRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    productTypes: productTypeObjectSelector.getDenormalizedObjects()(state)
  };
};

export const ProductTypeSelect = connect(
  mapStateToProps,
  {
    fetchProductTypes: productTypeObjectActions.fetchObjects
  }
)(ProductTypeSelectComponent);
