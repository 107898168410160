import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { func, string, bool, array, object, number, any } from "prop-types";
import { Select } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  actions as sprayMixRecipeObjectActions,
} from '../../../store/object/sprayMixRecipe/actions';
import { sprayMixRecipeSelector } from "../../../store/object/sprayMixRecipe/selectors";
import { sprayMixRecipeRequestSelector } from "../../../store/request/sprayMixRecipe/selectors";
import { createOptionsFromObjects } from "../../../helpers/form/util";

/**
 * Spray Mix Recipe Select Component
 * @param {*} disabled
 * @param {*} fetchRecipes
 * @param {*} id
 * @param {*} input
 * @param {*} isLoadedAll
 * @param {*} isLoadingAll
 * @param {*} loadAllError
 * @param {*} label
 * @param {*} recipeId
 * @param {*} recipes
 * @param {*} placeholder
 * @param {*} required 
 */
const SprayMixRecipeSelectComponent = ({
  disabled,
  id,
  input,
  label,
  recipeId,
  placeholder,
  required,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  fetchRecipes,
  recipes,
  onChange,
}) => {
  if (input && input.value) {
    recipeId = input.value;
  }
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchRecipes();
    }
  }, [isLoadedAll, isLoadingAll, fetchRecipes, loadAllError]);

  const [hasFocus, setHasFocus] = useState(false);
  const activeRecipes = recipes.filter(r => r.is_active);
  const options = createOptionsFromObjects(activeRecipes, 'id', 'name');

  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!recipeId,
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={recipeId}
        search
        name="units"
        placeholder={placeholder}
        noResultsMessage={<p className="text-center">No results <Link to={'#mix-recipes'} className='ml-1'>Create One</Link></p>}
        selection
        options={options}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (input && input.onChange) {
            input.onChange(value);
          }
          if (onChange) {
            const recipe = recipes.find(r => r.id === value);
            onChange(recipe);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
          if (onChange) {
            const recipe = recipes.find(r => r.id === value);
            onChange(recipe);
          }
        }}
      />
    </div>
  );
};

SprayMixRecipeSelectComponent.propTypes = {
  onSelect: func,
  onChange: func,
  placeholder: string,
  disabled: bool,
  id: any,
  input: object,
  label: string,
  recipeId: number,
  recipes: array,
  required: bool,
};

const mapStateToProps = (state, props) => {
  const recipeObjectSelector = sprayMixRecipeSelector();
  const requestSelector = sprayMixRecipeRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    recipes: recipeObjectSelector.getDenormalizedObjects()(state),
  };
};

export const SprayMixRecipeSelect = connect(
  mapStateToProps,
  {
    fetchRecipes: sprayMixRecipeObjectActions.fetchObjects
  }
)(SprayMixRecipeSelectComponent);
