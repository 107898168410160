import { filter, isEmpty, isArray, isObject, isString, isNumber } from "lodash";

/**
 * Filter out any rows that don't have matching values for the column filters.
 * @param rows
 * @param attributeFilter
 * @returns {Array}
 */
export const filterWithMultiple = (rows, attributeFilter) => {
  let finalResults = [];

  let currentConditions = {};
  let nestedConditions = {};

  let filterKeys = Object.keys(attributeFilter);
  let nestedKeys = [];

  filterKeys.forEach((key, filterIndex) => {
    // If filter value is object, not array, it is nested filter (like {att: {potted = [1]}})
    if (isObject(attributeFilter[key]) && !isArray(attributeFilter[key])) {
      nestedKeys = Object.keys(attributeFilter[key]);
      nestedKeys.forEach((nestedKey, nestedFilterIndex) => {
        if (isObject(attributeFilter[key][nestedKey]) && !isArray(attributeFilter[key][nestedKey])) {
          // Too much nesting going on. Fallback on lodash.
          finalResults = filter(rows, attributeFilter);
        } else {
          nestedConditions = getConditions(
            attributeFilter[key],
            nestedKey,
            key
          );
          finalResults = filterMultipleConditions(
            rows,
            finalResults,
            nestedConditions,
            key,
            filterIndex
          );
        }
      });
    }
    // If filter value is array, it is not nested, it is like [{LOCATION: ['DS,'CG']},{...}]
    else if (isArray(attributeFilter[key])) {
      // First we seperate each value into its own condition.
      currentConditions = getConditions(attributeFilter[key], key);
      // Then populate or filter finalResults array.
      finalResults = filterMultipleConditions(
        rows,
        finalResults,
        currentConditions,
        key,
        filterIndex
      );
    } else if (
      isString(attributeFilter[key]) ||
      isNumber(attributeFilter[key])
    ) {
      if (filterIndex > 0) {
        finalResults = filter(finalResults, attributeFilter);
      } else {
        finalResults = filter(rows, attributeFilter);
      }
    }
  });

  if (filterKeys.length > 0) {
    return finalResults;
  }

  return rows;
};

/**
 *  @param filters
 *  @param key
 *  @param topLevelKey
 *  @returns {Object}
 */
const getConditions = (filters, key, topLevelKey = false) => {
  let conditions = [];
  let temporaryFilterObject = {};
  if (topLevelKey && isArray(filters[key])) {
    // Loop nested filters, create nested conditions objects.
    filters[key].forEach((value) => {
      if (!isArray(conditions[topLevelKey])) {
        conditions[topLevelKey] = [];
      }
      if (!isArray(temporaryFilterObject[topLevelKey])) {
        temporaryFilterObject[topLevelKey] = [];
      }
      temporaryFilterObject[topLevelKey].push({
        [topLevelKey]: { [key]: value },
      });
    });
    conditions = temporaryFilterObject;
  } else {
    // Loop filters, create conditions objects.
    filters.forEach((value) => {
      if (!isArray(conditions[key])) {
        conditions[key] = [];
      }
      conditions[key].push({
        [key]: value,
      });
    });
  }
  return conditions;
};

/**
 *
 * Filter out any rows that don't have matching values for the column filters.
 *
 * @param rows
 * @param finalResults
 * @param currentConditions
 * @param key
 * @param filterIndex
 * @returns {Array}
 */
const filterMultipleConditions = (
  rows,
  finalResults,
  columnFilters,
  key,
  filterIndex
) => {
  // This variable will stay the same for each column condition.
  // Without this, second value always returns no results because they
  // were already filtered from finalResults with previous column condition.
  let currentResults = [...finalResults];

  if(isArray(columnFilters[key])) {
    columnFilters[key].forEach((condition, conditionIndex) => {
      if (filterIndex > 0 && !isEmpty(finalResults)) {
        if (conditionIndex === 0) {
          finalResults = filter(currentResults, condition);
        } else {
          finalResults.push(...filter(currentResults, condition));
        }
      } else {
        finalResults.push(...filter(rows, condition));
      }
    });
  }

  return finalResults;
};
