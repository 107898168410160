import { renderActionCell } from "./ActionCell";

export const columns = [
  {
    title: "Actions",
    isExcludeFromExport: true,
    isSortDisabled: true,
    width: 2,
    renderer: renderActionCell,
    path: "id"
  },
  {
    title: "Name",
    path: "name",
    width: 7,
    isSearchable: true
  },
  {
    title: "Email",
    path: "email",
    width: 5,
    isSearchable: true
  }
];
