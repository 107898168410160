import cx from "classnames";
import React, { useState } from "react";
import { get, isEmpty, isNull, orderBy } from "lodash";
import { Select } from "semantic-ui-react";

/**
 * A component used to render a group of checkboxes
 */
export const FormSelectInput = ({
  options,
  input,
  id,
  label,
  placeholder,
  focus,
  className,
  multi,
  required,
  skipSort = false,
  disabled = false,
  meta: { touched, error } = "",
  clearable = true,
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  if (isEmpty(options)) {
    return null;
  }

  const { name } = input;
  const isValid = !error;
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !isNull(input.value)
  });

  className = cx({ valid: isValid }, className);
  if (!skipSort) {
    options = orderBy(
      options,
      [option => get(option, "text", "").toLowerCase()],
      ["asc"]
    );
  }
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        {...input}
        id={id}
        name={name}
        search
        selection
        clearable={clearable}
        disabled={disabled}
        multiple={multi}
        placeholder={placeholder}
        focus={focus}
        className={className}
        fluid
        options={options}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, data) => {
          input.onChange(data.value);
        }}
        onBlur={(e, data) => {
          if (data.value) {
            input.onChange(data.value);
          }
        }}
      />
    </div>
  );
};
