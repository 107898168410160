import cx from "classnames";
import { get } from "lodash";
import React, { useState } from "react";
import { Input } from "semantic-ui-react";

export const UserPinInput = ({
  input,
  id,
  placeholder,
  label,
  focus = false,
  className,
  size,
  autoComplete,
  required,
  disabled,
  readOnly,
  transparent,
  appendedLabel,
  meta: { modified, error }
}) => {
  const [hasFocus, setHasFocus] = useState(focus);
  const [dynamicInputType, setDynamicInputType] = useState(get(input, 'value') ? 'masked' : 'number');

  const isValid = !error;
  className = cx({ valid: isValid, masked: dynamicInputType !== 'number' }, className);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!input.value
  });
  const showError = !!(modified && error);
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Input
        {...input}
        action={dynamicInputType === 'number' ?
          {
            icon: 'eye slash',
            onClick: () => {
              setDynamicInputType('masked')
            },
            type: 'button',
            color: 'red'
          } :
          {
            icon: 'eye',
            onClick: () => {
              setDynamicInputType('number')
            },
            type: 'button',
            color: 'green'
          }
        }
        placeholder={placeholder}
        type={'number'}
        error={showError}
        readOnly={readOnly}
        size={size}
        focus={focus}
        transparent={transparent}
        disabled={disabled}
        autoComplete={autoComplete}
        className={className}
        pattern="[0-9]+$"
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        label={
          appendedLabel ? { basic: true, content: appendedLabel } : undefined
        }
        labelPosition={appendedLabel ? "right" : undefined}
        fluid
      />
      {showError && <span className="input-error">{error}</span>}
    </div>
  );
};
