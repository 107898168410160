import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as routeObjectActions 
} from '../../../store/object/route/actions';
import {
  routeSelector,
} from '../../../store/object/route/selectors';
import {
  routeRequestSelector,
} from '../../../store/request/route/selectors';

const ServiceRouteSelectComponent = ({
  name,
  disabled,
  fetchRoutes,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  placeholder,
  onSelect,
  serviceRouteId,
  routes,
  required,
  className,
  meta,
}) => {
  const error = meta ? meta.error : null;
  if (input && input.value) {
    serviceRouteId = input.value;
  }

  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchRoutes();
    }
  }, [isLoadedAll, isLoadingAll, fetchRoutes, loadAllError]);

  const serviceRouteOptions = createOptionsFromObjects(routes, 'name');
  const isValid = !error;
  className = cx({ valid: isValid }, className);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!serviceRouteId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        {...input}
        upward={false}
        loading={isLoadingAll}
        fluid
        error={!isValid}
        disabled={disabled}
        size="huge"
        value={serviceRouteId}
        search
        name={name}
        placeholder={placeholder}
        selection
        options={serviceRouteOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelect) {
            onSelect(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

ServiceRouteSelectComponent.propTypes = {
  fetchRoutes: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  onSelect: func,
  placeholder: string,
  routes: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const objectSelector = routeSelector();
  const requestSelector = routeRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    routes: objectSelector.getDenormalizedObjects()(state)
  };
};

export const ServiceRouteSelect = connect(
  mapStateToProps,
  {
    fetchRoutes: routeObjectActions.fetchObjects,
  }
)(ServiceRouteSelectComponent);
