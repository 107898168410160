
import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  actions as salesGoalRequestActions,
} from '../../request/salesGoal/actions';
import { actions as salesGoalViewActions } from '../../view/salesGoal/actions';
import { salesGoalSelector } from './selectors';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { get } from 'lodash';
import { normalize } from 'normalizr';
import { salesGoalsSchema } from '../../../helpers/normalizers/salesGoal';
import { upsertNormalizedEntities } from '../../../helpers/normalizers';

const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObjects = id => {
  return async (dispatch, getState) => {
    dispatch(salesGoalRequestActions.setLoadAllError(null));
    dispatch(salesGoalRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_URL,
        "data.data"
      );
      return result;
    } catch (e) {
      captureException(e);
      dispatch(salesGoalRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(salesGoalRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch sales goals from api.
 * @returns {Function}
 */
 export const fetchGoals = () => {
  return async (dispatch, getState) => {
    dispatch(salesGoalRequestActions.setLoadAllError(null));
    dispatch(salesGoalRequestActions.setIsLoadingAll(true));
    dispatch(salesGoalRequestActions.setIsLoadedAll(false));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_GOALS_URL,
        "data.data"
      );
      const entities = get(
        normalize(result.goals, salesGoalsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(salesGoalViewActions.setAll(result.goals.map(goal => goal.id), result.goals.length));
      dispatch(salesGoalRequestActions.setIsLoadedAll(true));
      return result;
    } catch (e) {
      captureException(e);
      dispatch(salesGoalRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(salesGoalRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a goal on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
 export const upsertGoal = (data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(salesGoalRequestActions.setSaveError(null));
    dispatch(salesGoalRequestActions.setIsSaving(true));
    try {
      // Get the result of the upsert
      const result = await upsertObjectToAPI(constants.UPSERT_GOAL_URL, data, true, true, 'data.data');
      // Add the upserted goal to our objects
      const entities = get(
        normalize([result.goal], salesGoalsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      // Get objects list from state.
      const state = getState();
      const currentGoals = salesGoalSelector().getObjects()(state);      
      // Set our goals view in redux with new goal.
      dispatch(salesGoalViewActions.setAll(currentGoals.map(goal => goal.id), currentGoals.length));
      // Execute callback if we have one
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      console.log(e)
      captureException(e);
      dispatch(salesGoalRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(salesGoalRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  fetchGoals,
  upsertGoal,
};
