import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as productClassObjectActions,
} from '../../../store/object/productClass/actions';
import {
  productClassSelector,
} from '../../../store/object/productClass/selectors';
import {
  productClassRequestSelector,
} from '../../../store/request/productClass/selectors';

const ProductClassSelectComponent = ({
  disabled,
  fetchProductClasses,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  placeholder,
  onSelectProductClass,
  productClassId,
  productClasses,
  required,
  activeOnly = false
}) => {
  if (input && input.value) {
    productClassId = input.value;
  }

  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchProductClasses();
    }
  }, [isLoadedAll, isLoadingAll, fetchProductClasses, loadAllError]);

  const classes = !activeOnly ? productClasses : productClasses.filter(pc => pc.active)
  const productClassOptions = createOptionsFromObjects(classes);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!productClassId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        {...input}
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={productClassId}
        search
        name="productClass"
        placeholder={placeholder}
        selection
        options={productClassOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectProductClass) {
            onSelectProductClass(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

ProductClassSelectComponent.propTypes = {
  fetchProductClasses: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  onSelectProductClass: func,
  placeholder: string,
  productClasses: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const productClassObjectSelector = productClassSelector();
  const requestSelector = productClassRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    productClasses: productClassObjectSelector.getDenormalizedObjects()(state)
  };
};

export const ProductClassSelect = connect(
  mapStateToProps,
  {
    fetchProductClasses: productClassObjectActions.fetchObjects
  }
)(ProductClassSelectComponent);
