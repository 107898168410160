import dayjs from 'dayjs';
import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  serviceVisitSchema,
  serviceVisitsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as serviceVisitRequestActions,
} from '../../request/serviceVisit/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as serviceVisitViewActions,
} from '../../view/serviceVisit/actions';
import { serviceVisitViewSelector } from '../../view/serviceVisit/selectors';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';

const objectActions = makeObjectActions(constants);

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(serviceVisitRequestActions.setSaveError(null));
    dispatch(serviceVisitRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.serviceVisit], serviceVisitsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.serviceVisit);
      }
    } catch (e) {
      captureException(e);
      dispatch(serviceVisitRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(serviceVisitRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the service visits that took place in a certain date range
 * @param startDate
 * @param endDate
 * @param tableState
 * @returns {Function}
 */
export const fetchVisitsForDateRange = (startDate, endDate, tableState) => {
  return async dispatch => {
    dispatch(serviceVisitRequestActions.setLoadAllError(null));
    dispatch(serviceVisitRequestActions.setIsLoadingAll(true));
    try {
      const startDateJs = startDate ? dayjs(startDate) : null;
      const endDateJs = endDate ? dayjs(endDate) : null;

      if (startDateJs && endDateJs && (startDateJs.isBefore(endDateJs) || startDateJs.isSame(endDateJs))) {
        const result = await fetchObjectsFromAPI(
          constants.FETCH_DATE_RANGE_URL.replace(
            ":startDate",
            startDateJs.format("YYYY-MM-DD")
          ).replace(":endDate", endDateJs.format("YYYY-MM-DD"))
        );

        const entities = get(
          normalize(result, serviceVisitsSchema),
          "entities",
          {}
        );

        dispatch(upsertNormalizedEntities(entities));

        // Store the full list of ids in the reducer
        dispatch(
          serviceVisitViewActions.setRelatedObjects(
            constants.RELATION_SERVICE_VISITS,
            constants.ALL,
            result.map(serviceVisit => {
              return serviceVisit.id;
            })
          )
        );

        dispatch(updateServiceVisitsSortFilterLimit(tableState));
      } else {
      }
    } catch (e) {
      captureException(e);
      dispatch(serviceVisitRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(serviceVisitRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the service visits that took place in a certain date range
 * @param month
 * @param year
 * @param tableState
 * @returns {Function}
 */
export const fetchVisitsForMonthDate = (month, year, tableState) => {
  return async dispatch => {
    if (month && year) {
      dispatch(serviceVisitRequestActions.setLoadAllError(null));
      dispatch(serviceVisitRequestActions.setIsLoadingAll(true));
      try {
        const result = await fetchObjectsFromAPI(
          constants.FETCH_MONTH_YEAR_URL.replace(":month", month).replace(
            ":year",
            year
          )
        );
        return result;
      } catch (e) {
        console.log(e);
        captureException(e);
        dispatch(
          serviceVisitRequestActions.setLoadAllError(getErrorMessage(e))
        );
      } finally {
        dispatch(serviceVisitRequestActions.setIsLoadingAll(false));
      }
    }
  };
};

/**
 * Filter, Sort, and Trim the serviceVisits for the table
 * @param tableState
 * @returns {Function}
 */
export const updateServiceVisitsSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const state = getState();
    const serviceVisits = serviceVisitViewSelector().getRelatedObjects(
      constants.RELATION_SERVICE_VISITS,
      constants.ALL
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(serviceVisits, tableState, (ids, count) => {
        dispatch(
          serviceVisitViewActions.setRelatedFilteredList(
            constants.RELATION_SERVICE_VISITS,
            constants.ALL,
            ids,
            count
          )
        );
      })
    );
  };
};

export const toggleVisit = visit => {
  return async dispatch => {
    dispatch(serviceVisitRequestActions.setSaveError(null));
    dispatch(serviceVisitRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.TOGGLE_URL.replace(":visitId", visit.id),
        {}
      );
      visit.deleted = visit.deleted === 1 ? 0 : 1;
      const entities = get(
        normalize(visit, serviceVisitSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(serviceVisitRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(serviceVisitRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchVisitsForMonthDate,
  fetchVisitsForDateRange,
  toggleVisit,
  updateServiceVisitsSortFilterLimit,
  upsertObject
};
