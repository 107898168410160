import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as serviceScheduleObjectActions,
} from '../../../store/object/serviceSchedule/actions';
import {
  serviceScheduleSelector,
} from '../../../store/object/serviceSchedule/selectors';
import {
  serviceScheduleRequestSelector,
} from '../../../store/request/serviceSchedule/selectors';

const ServiceScheduleSelectComponent = ({
  disabled,
  fetchServiceSchedules,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  placeholder,
  onSelectServiceSchedule,
  serviceScheduleId,
  serviceSchedules,
  required,
  upward,
}) => {
  if (input && input.value) {
    serviceScheduleId = input.value;
  }

  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchServiceSchedules();
    }
  }, [isLoadedAll, isLoadingAll, fetchServiceSchedules, loadAllError]);

  const serviceScheduleOptions = createOptionsFromObjects(serviceSchedules);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-2": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!serviceScheduleId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        {...input}
        upward={upward}
        error={required && !serviceScheduleId}
        loading={isLoadingAll}
        fluid
        disabled={disabled}
        size="huge"
        value={serviceScheduleId}
        search
        name="serviceSchedule"
        placeholder={placeholder}
        selection
        clearable
        options={serviceScheduleOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectServiceSchedule) {
            onSelectServiceSchedule(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

ServiceScheduleSelectComponent.propTypes = {
  fetchServiceSchedules: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  onSelectServiceSchedule: func,
  placeholder: string,
  serviceSchedules: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const serviceScheduleObjectSelector = serviceScheduleSelector();
  const requestSelector = serviceScheduleRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    serviceSchedules: serviceScheduleObjectSelector.getDenormalizedObjects()(
      state
    )
  };
};

export const ServiceScheduleSelect = connect(
  mapStateToProps,
  {
    fetchServiceSchedules: serviceScheduleObjectActions.fetchObjects
  }
)(ServiceScheduleSelectComponent);
