import {
  get,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  docsSchema,
  estimateSchema,
  estimatesSchema,
  notesSchema,
  objectsSchema,
  salesCallActionsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as docRequestActions } from '../../request/doc/actions';
import {
  actions as estimateRequestActions,
} from '../../request/estimate/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as estimateViewActions } from '../../view/estimate/actions';
import { estimateViewSelector } from '../../view/estimate/selectors';
import {
  fetchFileFromAPI,
  fetchFileStreamFromAPI,
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  fetchObjectsFromAPIV2,
  makeObjectActions,
  putObjectToAPI,
  uploadFileToAPI,
  upsertObjectToAPI,
} from '../actions';
import { userSelector } from '../user/selectors';
import { allConstants as constants } from './constants';
import { estimateSelector } from './selectors';
import moment from 'moment';
import qs from 'qs';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = (tableState) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setLoadAllError(null));
    dispatch(estimateRequestActions.setIsLoadingAll(true));
    try {
      let url = constants.FETCH_LIST_URL;
      const response = await fetchObjectsFromAPIV2(url, tableState, "data");

      // Normalize the result and store the estimates in redux
      const entities = get(
        normalize(get(response, "data"), estimatesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        estimateViewActions.setList(
          get(response, "data").map(estimate => {
            return estimate.id;
          }),
          get(response, "total")
        )
      );
      dispatch(estimateRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Load a list of objects (PORTAL)
 * @param tableState
 * @returns {Function}
 */
export const fetchPortalObjects = tableState => {
  return async dispatch => {
    dispatch(estimateRequestActions.setLoadAllError(null));
    dispatch(estimateRequestActions.setIsLoadingAll(true));
    try {
      const estimates = await fetchObjectsFromAPIV2(constants.FETCH_PORTAL_LIST_URL, tableState);

      // Normalize the result and store the estimates in redux
      const entities = get(normalize(get(estimates, "data"), estimatesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      dispatch(
        estimateViewActions.setList(
          get(estimates, "data").map(estimate => {
            return estimate.id;
          }),
          get(estimates, "meta.total")
        )
      );
      dispatch(estimateRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Clone an estimate on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const cloneObject = (id, onSuccess) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setSaveError(null));
    dispatch(estimateRequestActions.setIsSaving(true));
    try {
      const result = await putObjectToAPI(
        constants.CLONE_URL.replace(":id", id),
        {}
      );
      const entities = get(
        normalize([result], estimatesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setSaveError(null));
    dispatch(estimateRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);

      const entities = get(
        normalize([result], estimatesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api (V1)
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObjectV1 = (data, onSuccess) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setSaveError(null));
    dispatch(estimateRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_V1_URL,
        data,
        false,
        true,
        "data.estimate",
        get(data, "estimate.id")
      );
      result.bedprep = result.labor;
      const entities = get(
        normalize([result], salesCallActionsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Send an sms message with a link to the estimate on the api
 * @param estimateId
 * @param sms
 * @param onSuccess
 * @returns {Function}
 */
export const textEstimate = (estimateId, sms, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setSmsError(null));
    //dispatch(estimateRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.SEND_SMS_URL.replace(":estimateId", estimateId).replace(
          ":number",
          sms
        ),
        {},
        false,
        true
      );
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      onError(getErrorMessage(e));
      dispatch(estimateRequestActions.setSmsError(getErrorMessage(e)));
    } finally {
      //dispatch(estimateRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Email a copy of the pdf of the estimate on the api
 * @param estimateId
 * @param email
 * @param onSuccess
 * @returns {Function}
 */
export const emailEstimate = (estimateId, email, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setMailError(null));
    //dispatch(estimateRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.SEND_EMAIL_URL,
        {
          data: { id: estimateId, email },
        },
        false,
        true
      );
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setMailError(getErrorMessage(e)));
      onError(getErrorMessage(e));
    } finally {
      //dispatch(estimateRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch bush estimate.
 * @param calculatedBushCount
 * @param priceLevel
 * @param serviceLevel
 * @param onSuccess
 * @returns {Function}
 */
export const fetchBushEstimate = (
  calculatedBushCount,
  priceLevel,
  serviceLevel,
  servicePeriodId,
  onSuccess
) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setCalculateError(null));
    dispatch(estimateRequestActions.setIsCalculating(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_SERVICE_PRICE_URL.replace(
          ":bushCount",
          calculatedBushCount
        )
          .replace(":priceLevel", priceLevel)
          .replace(":serviceLevel", serviceLevel)
          .replace(":servicePeriodId", servicePeriodId),
        "data.amount"
      );
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setCalculateError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsCalculating(false));
    }
  };
};

/**
 * Send a notification about the estimate
 * @param userId
 * @param message
 * @param estimateId
 * @param onSuccess
 * @returns {Function}
 */
export const notifyEstimate = (userId, message, estimateId, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(estimateRequestActions.setSaveError(null));
    dispatch(estimateRequestActions.setIsSaving(true));
    try {
      const user = userSelector().getDenormalizedObject(userId)(getState());
      const sendto = get(user, "email");
      const result = await upsertObjectToAPI(
        constants.SEND_NOTIFICATION_URL,
        {
          data: { id: estimateId, message, sendto },
        },
        false,
        true
      );

      toast.success("Notification sent successfully!");

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsSaving(false));
    }
  };
};

export const fetchEstimatePDF = (estimateId) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setLoadError(null));
    dispatch(estimateRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_PDF_URL.replace(":estimateId", estimateId),
        `estimate-${estimateId}.pdf`
      );
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param invoiceId
 * @returns {Function}
 */
export const fetchEstimatePDFForPortal = estimateId => {
  return async dispatch => {
    dispatch(estimateRequestActions.setLoadError(null));
    try {
      const blob = await fetchFileStreamFromAPI(
        constants.FETCH_PORTAL_ESTIMATE_PDF_URL.replace(":estimateId", estimateId),
        `estimate-${estimateId}.pdf`,
        'application/pdf'
      );
      return blob;

    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setLoadError(getErrorMessage(e)));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = (tableState) => {
  return async (dispatch, getState) => {
    const estimates = estimateSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(estimates, tableState, (ids, count) => {
        dispatch(estimateViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the docs from the api and store their result in our reducer
 * @param estimateId
 * @returns {Function}
 */
export const fetchDocs = (estimateId) => {
  return async (dispatch) => {
    dispatch(docRequestActions.setLoadAllError(null));
    dispatch(docRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_DOC_LIST_URL.replace(":estimateId", estimateId),
        `data`
      );

      const entities = get(
        normalize(result, { ...objectsSchema, docs: docsSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      dispatch(
        estimateViewActions.setRelatedObjects(
          constants.RELATION_DOCS,
          estimateId,
          result.docs.map((doc) => {
            return doc.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(docRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(docRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = (id) => {
  return async (dispatch) => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        estimateViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map((note) => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a doc on the api
 * @param estimateId
 * @param file
 * @returns {Function}
 */
export const upsertDoc = (estimateId, file, callback = false) => {
  return async (dispatch) => {
    dispatch(docRequestActions.setSaveError(null));
    dispatch(docRequestActions.setIsSaving(true));
    try {
      await uploadFileToAPI(constants.UPSERT_DOC_URL, file, {
        type: "estimate",
        objectId: estimateId,
      });

      if (!callback) {
        dispatch(fetchDocs(estimateId));
      } else {
        callback();
      }
    } catch (e) {
      console.log(e);
      captureException(e);
      dispatch(docRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(docRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Remove a doc from the API
 * @param docId
 * @param estimateId
 * @returns {Function}
 */
export const destroyDoc = (docId, estimateId, callback = false) => {
  return async (dispatch, getState) => {
    dispatch(docRequestActions.setSaveError(null));
    dispatch(docRequestActions.setIsSaving(true));
    try {
      await uploadFileToAPI(constants.DESTROY_DOC_URL.replace(":docId", docId));

      if (!callback) {
        dispatch(fetchDocs(estimateId));
      } else {
        callback();
      }
    } catch (e) {
      captureException(e);
      dispatch(docRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(docRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "estimate";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = estimateViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        estimateViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = (id) => {
  return async (dispatch, getState) => {
    dispatch(estimateRequestActions.setLoadError(null));
    dispatch(estimateRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(
          { estimate: result },
          {
            ...objectsSchema,
            estimate: estimateSchema,
          }
        ),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(estimateRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Download estimate list as excel file.
 * @param {*} params 
 * @returns 
 */
export const fetchEstimateDump = (tableState = false) => {
  return async dispatch => {
    dispatch(estimateRequestActions.setIsLoadingAll(true));
    dispatch(estimateRequestActions.setLoadAllError(null));
    const paramObject = {
      sort_col: get(tableState, "sortOrder.column"),
      sort_asc: !!get(tableState, "sortOrder.isAscending"),
      search: get(tableState, "search"),
    };
    if(get(tableState, "filter")){
      Object.keys(tableState.filter).forEach((value) => {
        paramObject[value] = tableState.filter[value];
      })
    }
    const params = qs.stringify(paramObject);
    const url = params ? `${constants.FETCH_ESTIMATE_DUMP_URL}?${params}` : constants.FETCH_ESTIMATE_DUMP_URL
    try {
      await fetchFileFromAPI(
        url,
        `estimate_bulk_dump_${moment().format('YYYY-MM-DD')}.csv`
        )
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Reset.
 * @param id
 * @returns {Function}
 */
export const reset = () => {
  return async (dispatch, getState) => {
    dispatch(estimateRequestActions.setIsLoading(false));
    dispatch(estimateRequestActions.setIsLoaded(false));
    dispatch(estimateRequestActions.setIsLoadingAll(false));
    dispatch(estimateRequestActions.setIsLoadedAll(false));
    dispatch(upsertNormalizedEntities([]));
    dispatch(estimateViewActions.setList([], 0));
  };
};

/**
 * Reset error messages.
 * @param id
 * @returns {Function}
 */
export const resetErrors = () => {
  return async (dispatch, getState) => {
    dispatch(estimateRequestActions.setLoadError(null));
    dispatch(estimateRequestActions.setSaveError(null));
    dispatch(estimateRequestActions.setSmsError(null));
    dispatch(estimateRequestActions.setMailError(null));
  };
};


/**
 * Make the bed packet
 * @param estimateId
 * @param onSuccess
 * @param onError
 * @returns {Function}
 */
 export const makeBedPacket = (estimateId, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch(estimateRequestActions.setSaveError(null));
    dispatch(estimateRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.MAKE_BED_PACKET_URL,
        { id: estimateId },
        false,
        true
      );
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setSaveError(getErrorMessage(e)));
      if(onError){
        onError(getErrorMessage(e));
      }      
    } finally {
      dispatch(estimateRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  emailEstimate,
  notifyEstimate,
  textEstimate,
  fetchEstimatePDF,
  fetchEstimatePDFForPortal,
  fetchObject,
  fetchObjects,
  fetchPortalObjects,
  fetchDocs,
  fetchNotes,
  fetchBushEstimate,
  upsertDoc,
  upsertNote,
  cloneObject,
  destroyDoc,
  updateSortFilterLimit,
  upsertObject,
  upsertObjectV1,
  resetErrors,
  reset,
  makeBedPacket,
  fetchEstimateDump,
};
