import React from "react";
import { string, func } from "prop-types";
import { Button, Dropdown } from "semantic-ui-react";
import {
  OPTIONS_PURCHASE_ORDER_FILTER,
  PO_FILTER_ALL
} from "../../../../../constants";

export const PurchaseOrderListFilters = ({ dateFilter, onChangeFilter }) => {
  const isActive = dateFilter !== PO_FILTER_ALL;

  return (
    <div className="pt-2">
      <Button.Group default={!isActive} color={isActive ? "blue" : null}>
        <Dropdown
          text={isActive ? dateFilter : "Filter Results"}
          icon="filter"
          upward={false}
          floating
          labeled
          button
          direction="right"
          className="icon"
        >
          <Dropdown.Menu>
            <Dropdown.Header icon="plus" content="Filter Results" />
            {OPTIONS_PURCHASE_ORDER_FILTER.map(option => {
              return (
                <Dropdown.Item
                  key={option}
                  value={option}
                  onClick={(event, { value }) => {
                    onChangeFilter(value);
                  }}
                >
                  {option}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    </div>
  );
};

PurchaseOrderListFilters.propTypes = {
  dateFilter: string.isRequired,
  onChangeFilter: func.isRequired
};
